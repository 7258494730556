<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">证书管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">外部证书管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc operationControlExamination" style="align-items: flex-start;">
          <div class="searchbox" style="display: flex; align-items: center;margin-bottom: 15px;">
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel">姓名:</span>
              <el-input
                  v-model="retrievalData.externalName"
                  type="text"
                  clearable
                  size="small"
                  placeholder="请输入姓名"
              />
            </div>
            <div title="手机号" class="searchboxItem ci-full">
              <span class="itemLabel">手机号:</span>
              <el-input
                  v-model="retrievalData.externalMobile"
                  type="text"
                  clearable
                  size="small"
                  placeholder="请输入手机号"
              />
            </div>
            <div title="培训工种" class="searchboxItem ci-full">
              <span class="itemLabel">培训工种:</span>
              <el-input
                  v-model="retrievalData.externalOccupation"
                  type="text"
                  clearable
                  size="small"
                  placeholder="请输入培训工种"
              />
            </div>
          </div>
          <div class="searchbox">
            <div title="培训等级" class="searchboxItem ci-full">
              <span class="itemLabel">培训等级:</span>
              <el-select v-model="retrievalData.trainLevel" placeholder="请选择培训等级" size="small" clearable>
                <el-option v-for="item in trainLevelList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="招生老师" class="searchboxItem ci-full">
              <span class="itemLabel">招生老师:</span>
              <el-select size="small" clearable v-model="retrievalData.concatMan" placeholder="请选择招生老师">
                  <el-option
                      v-for="item in teacherIdList"
                      :key="item.channelId"
                      :label="item.contactUser"
                      :value="item.channelId">
                  </el-option>
              </el-select>
              <!-- <el-select v-model="retrievalData.concatMan" placeholder="请选择招生老师" clearable filterable remote
                :remote-method="getCtChannelSelectList" size="small">
                <el-option v-for="item in teacherIdList" :key="item.channelId" :label="item.contactUser"
                  :value="item.channelId">
                </el-option>
              </el-select> -->
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            <el-button
                      class="bgc-bv"
                      style="margin: 0 10px"
                      round
                      @click="importStudentInfo()"
              >导入学员</el-button
              >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" width="60" fixed :index="indexMethod" />
              <el-table-column label="姓名" align="left" show-overflow-tooltip prop="externalName" width="80" fixed />
              <el-table-column label="身份证号" align="left" show-overflow-tooltip prop="externalIdcard" min-width="180" />
              <el-table-column label="手机号" align="left" show-overflow-tooltip prop="externalMobile" min-width="150" />
              <el-table-column label="培训工种" align="left" show-overflow-tooltip prop="externalOccupation" min-width="150" />
              <el-table-column label="培训等级"  align="left" show-overflow-tooltip prop="externalLevel" min-width="100" />
              <el-table-column label="招生老师" align="left" show-overflow-tooltip prop="concatMan" min-width="120" />
              <el-table-column label="发证机构" align="left" show-overflow-tooltip prop="authorityName" min-width="200" />
              <el-table-column label="证书编号" align="left" show-overflow-tooltip prop="externalCertificateNo" min-width="200" />
              <el-table-column label="发证日期" align="left" show-overflow-tooltip prop="issueDate" min-width="120" />
              <el-table-column label="有效期至" align="left" show-overflow-tooltip prop="issueDateTo" min-width="120" />
              <el-table-column label="操作" align="center" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button type="text" style="padding:0px 5px" size="mini"
                    @click="handleDel(scope.row.externalId)">删除</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <!--鉴定机构通用导入-->
      <!-- businessType  导入业务类型标识 [ BUSINESS_TYPE ]-->
      <!-- importType  导入类型 学员-->
      <!-- unit  单位 学员-人-->
      <!--fileType  EXCEL  ZIP-->
      <accreditationAgencyImport
        ref="accreditationAgencyImport"
        @eventBus="accreditationAgencyImportBack"
        businessType="CERTIFYING_EXTERNAL_IMPORT"
        importType="学员"
        unit="人"
        fileType="EXCEL"
      />
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import accreditationAgencyImport from "@/components/accreditationAgencyImport.vue";
export default {
  name: "operate/Examination",
  components: {
    accreditationAgencyImport,
    Empty,
    PageNum,
  },
  mixins: [List],
  data () {
    return {
      retrievalData:{
        externalName: "",
        externalMobile: "",
        externalOccupation: "",
        trainLevel: "",
        concatMan: "",
      },
      dialogVisible: false,
      paperIsPass: "",
      // 培训工种 - 下拉数据
      occupationList: [],
      // 培训等级 - 下拉数据
      trainLevelList: [],
      // 招生老师 - 下拉数据
      teacherIdList: [],
    };
  },
  created () {
    this.getInvoice_type();
    this.getCtChannelSelectList()
  },
  computed: {},
  methods: {
    importStudentInfo(){
      this.$refs.accreditationAgencyImport.showPopup('1')
    },
    accreditationAgencyImportBack(){
      this.getData(-1)
    },
    // 获取 - 获取字典
    getInvoice_type() {
      // 培训等级
      let arr = this.$setDictionary("ET_TRAIN_LEVEL", "list");
      for (const key in arr) {
        this.trainLevelList.push({
          label: arr[key],
          value: key
        });
      }
    },
    getCtChannelSelectList() {
        this.$post("/biz/channel/list", {} ,3000,true,6).then(res => {
            this.teacherIdList = res.data || [];
            this.teacherIdList.unshift({
                contactUser: "自招",
                channelId: -1000,
            });
        },).catch(e => {
            console.error("获取招生渠道数据出错", e);
        });
    },
    // // 获取 - 培训老师
    // getCtChannelSelectList(query) {
    //   this.$post('/biz/cms/certificate/external/ctChannelSelectList', {
    //     contactUser: query
    //   },3000,true,6).then(ret => {
    //     this.teacherIdList = ret.data || []
    //   }).catch(err => {
    //     return;
    //   })
    // },
    getData (pageNum = 1) {
      let params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      params = {
        ...params,
        ...this.retrievalData,
      };
      this.doFetch({
        url: "/biz/cms/certificate/external/pageList",
        params,
        pageNum,
      },true,6);
    },
    // 删除
    handleDel(id) {
      this.doDel({
        title: "删除",
        url: "/biz/cms/certificate/external/delete",
        msg: "证书一经删除，将不能找回，确认删除吗？",
        ps: {
          type: "post",
          data: { externalId: id },
        },
      },true,6);
    },
  },

};
</script>
<style lang="less" scoped>
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}

.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}

.operationControlExamination {
  >div {
    justify-content: space-between;
  }

  .el-form {
    display: flex;

    .el-form-item {
      margin-bottom: 0;
    }

    .btns {
      .el-form-item__content {
        margin-left: 0 !important;
      }
    }
  }

  .searchboxs {
    justify-content: flex-start;
  }
}

.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
